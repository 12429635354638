.Dashboard .introduction {
    text-align: left;
    margin: 0;
    font-family: "Crimson Text", serif;
    color: #ffffff;
    max-width: 500px;
    margin: 20% 0 20% 0;
}
.Dashboard .introduction h1 {
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family: "Crete Round", serif;
    font-size: 38px;
    font-weight: 400;
    margin: 0 0 .5em 0;
    line-height: 1em;
}
.Dashboard .introduction h3 {
    font-size: 22px;
    font-weight: 500;
    display: block;
    margin-bottom: 1em;
}
.Dashboard .description h3 {
    display: inline;    
    font-size: 18px;
}
.Dashboard .description p {
    margin: 0;   
    font-size: 14px;
}
.Dashboard .projects {
    display: flex;
    justify-content: center;
}
.Dashboard .description button {
    height: auto;
    width: auto;
    padding: .5em .9em;
    background-color: #9D2828;
    color: white;
    margin-top: 1em;
    font-family: "Crimson Text", serif;
}
.Dashboard .profile-picture-container {
    position: absolute;
    width: 40%;
    height: 90%; 
    bottom: 0;
    right: 100px;
    max-height: 90%;
}
.Dashboard .profile-picture {
    width: auto;
    height: 100%; 
    object-fit: cover; 
}
@media (max-width: 991px) {
    .Dashboard .profile-picture {
        opacity: 0;
    }
}